import React, {useContext, useEffect, useRef, useState} from "react";
import GrayLine from "../../ui/GrayLine.jsx";
import CloseIcon from "@mui/icons-material/Close";
import {differenceInDays, eachDayOfInterval, format, subDays} from "date-fns";
import {useTranslation} from "react-i18next";
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import Avatar from "../../Shared-components/Avatar.jsx";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import FingerprintOutlinedIcon from "@mui/icons-material/FingerprintOutlined";
import SuperHostLogo from "../../../component/ui/SuperHostLogo.jsx";
import axios from "axios";
import Cookies from "js-cookie";
import {fr} from "date-fns/locale";
import {Usercontext} from "../../../context/Usercontexte.js";
import LoadingBtn from "../../ui/LoadingBtn.jsx";
import NewPriceDetailsModal from "../../DetailsPage/NewPriceDetails.jsx";
import {useClickOutside} from "../../../hooks/UseClickOutSide.js";

function ReservationsDetails({
                                 ResDetailsId,
                                 setResDetailsId,
                                 IsCalander,
                                 filteredReservations,
                             }) {
    const [Details, setDetails] = useState(null);
    const [ResDetails, setResDetails] = useState(null);
    const [IsLoading, setIsLoading] = useState(false);
    const [NewPriceDetails, setNewPriceDetails] = useState(null);
    const [isTheselectedDateMathNewPrice, setIsTheselectedDateMathNewPrice] =
        useState(null);
    const [OpenNewPriceDetailsModal, setOpenNewPriceDetailsModal] =
        useState(false);

    const {id} = useParams();
    const {t} = useTranslation();
    const {currency, exchangeRate} = useContext(Usercontext);
    const NavTo = useNavigate();
    const location = useLocation();
    const isDashboard = location.pathname.includes("/dashboard");
    const ModalRef = useRef();

    useClickOutside(ModalRef, () => setOpenNewPriceDetailsModal(false));

    const SendMsg = async () => {
        const userToken = Cookies.get("Token");
        try {
            const UserId = localStorage.getItem("ID_Utulisateur");
            axios
                .post(
                    "https://visiontunisie.com/api/v1/Conversation/insertConverstaion",
                    {
                        CheckInDateTime: ResDetailsId.start,
                        CheckOutDateTime: ResDetailsId.end,
                        User1: ResDetails.User.ID_Utulisateur, 
                        User2: UserId,
                        Slog: ResDetailsId.slog,
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${userToken}`,
                        },
                    }
                )
                .then((response) => {
                    NavTo(`/dashboard/messages/${response.data.UID_Conversation}`);
                })
                .catch((err) => {
                    console.error(err);
                });
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        if (!ResDetailsId) return;
        setIsLoading(true);
        const userToken = Cookies.get("Token");

        axios
            .post(
                "https://visiontunisie.com/api/v1/GestionDesReservations/getByDateRange",
                {
                    startDate: ResDetailsId.start,
                    endDate: ResDetailsId.end,
                    Slog: ResDetailsId.slog
                },
                {
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                    },
                }
            )
            .then((response) => {
                setResDetails(response.data.Reservation);
                setIsLoading(false);
            })
            .catch((err) => {
                console.error(err);
                setIsLoading(false);
            });
    }, [ResDetailsId]);

    useEffect(() => {
        if (!ResDetails) return;
        checkDatesAndShowModal();
    }, [ResDetails]);

    if (!ResDetails) {
        return (
            <div className={`${ResDetailsId ? "bottom-0" : "-bottom-[100%]"} fixed right-0 lg:relative duration-200 ease-in bg-white h-[90vh] ${IsCalander ? "pt-9" : "py-0"} lg:pb-14 z-[150] gap-[1rem] text-gray-800 w-full flex flex-col`}>
                {ResDetailsId && (
                    <span onClick={() => setResDetailsId(null)} className="bg-black lg:hidden fixed top-0 right-0 -z-30 h-full w-full opacity-30"></span>
                )}
                <div className={`${IsCalander ? "py-10" : "py-12 lg:py-0"} px-3 relative overflow-auto lg:py-0 pb-10 rounded-t-3xl bg-white z-[90] flex flex-col gap-4`}>
                    <span className={`${!IsCalander ? "lg:hidden" : ""} cursor-pointer text-main z-50 absolute top-2 right-2`} onClick={() => setResDetailsId(null)}>
                        <CloseIcon/>
                    </span>
                    <div className={`${IsCalander ? "pt-10" : ""} flex z-40 justify-between items-start`}>
                        <span className="flex flex-col gap-2">
                            <h2 className="font-semibold">{ResDetailsId?.guestName}</h2>
                            <p className="text-gray-400 font-medium text-sm">{ResDetailsId?.source === 'Direct' ? 'Direct reservation on SuperHote' : ResDetailsId?.source}</p>
                            <p className="text-gray-400 font-medium text-sm">
                                {ResDetailsId?.start && format(new Date(ResDetailsId?.start), "dd MMMM", {locale: fr})} - {ResDetailsId?.end && format(new Date(new Date(ResDetailsId?.end).setDate(new Date(ResDetailsId?.end).getDate() + 1)), "dd MMMM", {locale: fr})}
                            </p>
                            <p className="text-gray-400 font-medium text-sm">
                                {ResDetailsId?.guestDetails?.adults} {t("Labels.Travelers")} 
                                {ResDetailsId?.guestDetails?.children && ` + ${ResDetailsId?.guestDetails?.children} ${t("DetailsPage.children")}`}
                            </p>
                        </span>
                        <Avatar img={ResDetailsId?.image}/>
                    </div>
                    <GrayLine/>

                    <span className="flex flex-col gap-4">
                        <p className="text-lg lg:text-xl text-gray-700 font-semibold">
                            {t("ReservationDetails.infoAbout")} {ResDetailsId?.guestName}
                        </p>
                        {ResDetailsId?.guestDetails?.phone && (
                            <span className="text-gray-600 flex items-center gap-2 cursor-pointer font-medium text-sm">
                                <HomeOutlinedIcon className="scale-110"/>
                                <h3>{ResDetailsId?.guestDetails?.phone}</h3>
                            </span>
                        )}
                    </span>
                    <GrayLine/>

                    <div className="flex flex-col gap-3">
                        <p className="text-lg lg:text-xl text-gray-700 font-semibold">
                            {t("ReservationDetails.reservationDetails")}
                        </p>
                        <div className="flex flex-col gap-2">
                            <span className="text-gray-500 flex flex-col">
                                <h2 className="font-semibold">{t("Labels.guest")}</h2>
                                <p className="text-sm">{ResDetailsId?.guestDetails?.adults || 1}</p>
                            </span>
                            <GrayLine/>
                        </div>
                        <div className="flex flex-col gap-2">
                            <span className="text-gray-500 flex flex-col">
                                <h2 className="font-semibold">{t("ReservationDetails.CheckIn")}</h2>
                                <p className="text-sm">{ResDetailsId?.start && format(new Date(ResDetailsId?.start), "dd MMMM", {locale: fr})}</p>
                            </span>
                            <GrayLine/>
                        </div>
                        <div className="flex flex-col gap-2">
                            <span className="text-gray-500 flex flex-col">
                                <h2 className="font-semibold">{t("ReservationDetails.CheckOut")}</h2>
                                <p className="text-sm">{ResDetailsId?.end && format(new Date(new Date(ResDetailsId?.end).setDate(new Date(ResDetailsId?.end).getDate() + 1)), "dd MMMM", {locale: fr})}</p>
                            </span>
                            <GrayLine/>
                        </div>
                        {ResDetailsId?.bookingId && (
                            <div className="flex flex-col gap-2">
                                <span className="text-gray-500 flex flex-col">
                                    <h2 className="font-semibold">Booking ID</h2>
                                    <p className="text-sm">{ResDetailsId?.bookingId}</p>
                                </span>
                                <GrayLine/>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }

    const checkInDate = new Date(ResDetails.CheckInDateTime.split('T')[0] + 'T00:00:00');
    const checkOutDate = new Date(ResDetails.CheckOutDateTime.split('T')[0] + 'T00:00:00');
    let endDateObj = new Date(checkOutDate);
    endDateObj.setDate(endDateObj.getDate() + 1);
    const daysCounts = differenceInDays(endDateObj, checkInDate);

    const checkDatesAndShowModal = () => {
        const adjustedEndDate = subDays(new Date(endDateObj), 1);
        const stayDates = eachDayOfInterval({
            start: new Date(checkInDate),
            end: adjustedEndDate,
        });

        let foundMatchingPrice = false;
        const selectedPrices = stayDates?.map((date) => {
            const formattedDate = format(date, "yyyy-MM-dd");
            const matchingPrice = ResDetails?.NewPrice?.find(
                (price) => price.DateNewPrice === formattedDate
            );
            if (matchingPrice) {
                foundMatchingPrice = true;
            }
            return {
                date: formattedDate,
                price: matchingPrice
                    ? matchingPrice.NouveauPrix
                    : ResDetails?.PrixNuitee,
            };
        });

        setIsTheselectedDateMathNewPrice(foundMatchingPrice);
        setNewPriceDetails(selectedPrices);
    };

    const GusetInfo = [
        {
            title: t("ReservationDetails.verifiedIdentity"),
            img: <FingerprintOutlinedIcon className=" scale-110"/>,
        },
        {
            title: ResDetails?.User?.Pays,
            img: <HomeOutlinedIcon className=" scale-110"/>,
        },
        {
            title: `Super Host ${t("ReservationDetails.memberSince")} ${
                ResDetails?.User?.DateHeureAjout &&
                format(ResDetails?.User?.DateHeureAjout, "yyyy", {locale: fr})
            }`,
            img: (
                <SuperHostLogo
                    stroke={16}
                    height={"23"}
                    width={"23"}
                    color={"#172A4C"}
                    className={"left-[-40%]"}
                />
            ),
        },
    ];

    const DateHeureReservation = new Date(ResDetails.DateHeureReservation);
    const formattedCheckIn = format(checkInDate, "dd MMMM", {locale: fr});
    const formattedCheckOut = format(endDateObj, "dd MMMM", {locale: fr});
    const formattedDateOfReservation = format(DateHeureReservation, "dd MMMM", {locale: fr});

    const ReservationDetails = [
        {
            title: t("Labels.guest"),
            p: `${ResDetails?.Voyageurs}`,
        },
        {
            title: t("ReservationDetails.CheckIn"),
            p: formattedCheckIn,
        },
        {
            title: t("ReservationDetails.CheckOut"),
            p: formattedCheckOut,
        },

        {
            title: t("ReservationDetails.dateOfReservation"),
            p: formattedDateOfReservation,
        },
    ];

    const TotalTcc = parseInt(ResDetails.TotalTTC);
    const PaymentDetails = [
        {
            fee: isDashboard ? (
                isTheselectedDateMathNewPrice ? (
                    <span
                        ref={ModalRef}
                        onClick={() => setOpenNewPriceDetailsModal(true)}
                        className=" cursor-pointer underline"
                    >
            {t("Labels.Hosting fees")}
          </span>
                ) : (
                    `    ${ResDetails?.PrixNuitee} TND x ${daysCounts}  ${t(
                        "DetailsPage.nights"
                    )} `
                )
            ) : isTheselectedDateMathNewPrice ? (
                <span
                    ref={ModalRef}
                    onClick={() => setOpenNewPriceDetailsModal(true)}
                    className=" cursor-pointer underline"
                >
          {t("Labels.Hosting fees")}
        </span>
            ) : (
                `${(ResDetails?.PrixNuitee * exchangeRate)?.toFixed(2)} ${
                    currency.symbol
                } x ${daysCounts}  ${t("DetailsPage.nights")}`
            ),
            total: isDashboard
                ? `${parseInt(ResDetails?.TotalAvantReductions)?.toFixed(2)}  TND`
                : `${parseInt(ResDetails?.TotalAvantReductions * exchangeRate)?.toFixed(
                    2
                )} ${currency.symbol}`,
        },
        {
            fee: t("Host.Prices.CleaningFee"),
            total: isDashboard
                ? `${ResDetails?.Fraisdemenage} TND`
                : `${(ResDetails?.Fraisdemenage * exchangeRate)?.toFixed(2)} ${
                    currency.symbol
                }`,
        },
        {
            fee: t("DetailsPage.ServiceFee"),
            total: `${ResDetails?.CommissionHote} %`,
        },
        {
            fee: `Total`,
            total: isDashboard
                ? `${parseInt(TotalTcc)?.toFixed(2)} TND`
                : `${(TotalTcc * exchangeRate)?.toFixed(2)} ${currency.symbol}`,
        },
    ];

    return (
        <>
            <div
                className={`    ${
                    ResDetailsId ? " bottom-0 " : " -bottom-[100%]    "
                } fixed right-0  lg:relative   duration-200 ease-in  bg-white h-[90vh] ${
                    IsCalander ? " pt-9 " : " py-0"
                }  lg:pb-14  z-[150]   gap-[1rem] text-gray-800 w-full flex flex-col`}
            >
                {IsLoading && (
                    <span className=" w-full z-[160] h-full bg-white absolute top-0 right-0">
            <LoadingBtn
                isLoading={true}
                BgColor={"white"}
                color={"#172A4C"}
                scale={"scale-[0.1]"}
            />
          </span>
                )}
                {ResDetailsId && (
                    <span
                        onClick={() => setResDetailsId(null)}
                        className=" bg-black lg:hidden  fixed top-0 right-0 -z-30   h-full w-full opacity-30"
                    >
            {" "}
          </span>
                )}
                {/* Less info about the reservation  */}
                <div
                    className={` ${
                        IsCalander ? " py-10" : "  py-12 lg:py-0"
                    }    px-3 relative  overflow-auto lg:py-0 py-8   pb-10  rounded-t-3xl bg-white z-[90]  flex flex-col gap-4`}
                >
          <span
              className={` ${
                  !IsCalander ? "lg:hidden " : ""
              }   cursor-pointer  text-main  z-50 absolute top-2 right-2`}
              onClick={() => setResDetailsId(null)}
          >
            <CloseIcon/>
          </span>
                    <div
                        className={` ${
                            IsCalander ? "pt-10" : ""
                        } flex z-40 justify-between items-start`}
                    >
            <span className=" flex flex-col gap-2">
              <h2 className=" font-semibold ">
                {" "}
                  {ResDetails?.User?.Nom} {ResDetails?.User?.Prenom}{" "}
              </h2>
              <p className=" text-gray-400 font-medium text-sm">
                {ResDetails?.Titre}
              </p>
              <p className=" text-gray-400 font-medium text-sm">
                {formattedCheckIn} - {formattedCheckOut} ({daysCounts})
              </p>
              <p className=" text-gray-400 font-medium text-sm">
                {ResDetails?.Voyageurs}{" "}
                  {ResDetails?.Voyageurs == 1
                      ? t("Labels.Traveler")
                      : t("Labels.Travelers")}{" "}
                  .{" "}
                  {isDashboard
                      ? parseInt(ResDetails?.TotalTTC)?.toFixed(2)
                      : (parseInt(ResDetails?.TotalTTC) * exchangeRate)?.toFixed(
                          2
                      )}{" "}
                  {isDashboard ? "TND" : currency.symbol}
              </p>
            </span>
                        <Avatar img={ResDetails?.User?.ImageProfile}/>
                    </div>
                    <GrayLine/>

                    <span className=" flex flex-col gap-4">
            <p className=" text-lg  lg:text-xl text-gray-700 font-semibold ">
              {t("ReservationDetails.infoAbout")} {ResDetails?.User?.Nom}{" "}
                {ResDetails?.User?.Prenom}{" "}
            </p>
                        {GusetInfo.map((Info, index) => (
                            <span key={index}
                                  className=" text-gray-600 flex items-center gap-2 cursor-pointer font-medium text-sm">
                {Info?.img}
                                <h3> {Info.title} </h3>
              </span>
                        ))}
                        <Link
                            rel="canonical"
                            className=" mt-2 underline"
                            to={`/user-profile/${ResDetails?.User?.ID_Utulisateur}`}
                        >
              {" "}
                            {t("ReservationDetails.showProfile")}
            </Link>
          </span>

                    {IsCalander && (
                        <div className=" pt-3 flex flex-col gap-4">
                            <button
                                onClick={SendMsg}
                                className="  w-full py-3 rounded-md border-2 border-main "
                            >
                                {t("ReservationDetails.sendMessage")}
                            </button>
                        </div>
                    )}
                    <GrayLine/>

                    <div className=" flex flex-col gap-3">
                        <p className="text-lg  lg:text-xl text-gray-700 font-semibold ">
                            {t("ReservationDetails.reservationDetails")}
                        </p>
                        {ReservationDetails.map((i, index) => (
                            <div key={index} className=" flex flex-col gap-2">
                <span className=" text-gray-500 flex flex-col ">
                  <h2 className=" font-semibold "> {i.title} </h2>
                  <p className=" text-sm ">{i.p}</p>
                </span>
                                <GrayLine/>
                            </div>
                        ))}
                    </div>
                    <GrayLine/>

                    <div className=" relative flex flex-col gap-3">
                        <p className=" text-lg  lg:text-xl text-gray-700 font-semibold ">
                            {t("ReservationDetails.PaymentSummary")}
                        </p>
                        <NewPriceDetailsModal
                            IsResrvationDetails={true}
                            setIsOpen={setOpenNewPriceDetailsModal}
                            isOpen={OpenNewPriceDetailsModal}
                            data={NewPriceDetails}
                            Total={(TotalTcc * exchangeRate)?.toFixed(2)}
                        />
                        {PaymentDetails?.map((i, index) => (
                            <div key={index} className=" flex flex-col gap-2">
                <span className=" text-gray-500 flex  justify-between ">
                  <p className=" font-medium "> {i.fee} </p>
                  <p className=" font-semibold text-sm ">{i.total}</p>
                </span>
                                <GrayLine/>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
}

export default ReservationsDetails;
