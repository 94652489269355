import {useLocation} from "react-router-dom";
import {Helmet} from "react-helmet-async";

export const SeoHelmet = ({pageTitle, pageType, pageImage, pageAuthor, pageDescription, lang}) => {
    const {pathname} = useLocation()


    const currentUrl = `https://superhost.com.tn${pathname}`;

    return (
        <>
            <Helmet>
                <meta httpEquiv="x-ua-compatible" content="ie=edge"/>
                <meta name="description"
                      content={pageDescription || "Enhance guest satisfaction and streamline operations with Super Host. Our award-winning team provides tailored solutions, redefining excellence in your business."}/>
                <meta name="robots" content="index, follow"/>
                <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no"/>
                <link rel="icon" href="/favicon.png"/>
                <meta property="og:description"
                      content={pageDescription || "Enhance guest satisfaction and streamline operations with Super Host. Our award-winning team provides tailored solutions, redefining excellence in your business."}/>
                <meta
                    property="og:locale"
                    content={
                        lang === "en" ? "en_US" :
                            lang === "fr" ? "fr_FR" :
                                lang === "es" ? "es_ES" :
                                    "en_US"
                    }
                />
                <meta name="publisher" content="Super Host"/>
                <link rel="canonical" href={currentUrl}/>
                <meta property="og:url" content={currentUrl}/>
                <title>
                    {(pageTitle && !pageTitle.includes('undefined')) && `${pageTitle} - Super Host`}
                </title>

                <meta property="og:title" content={(pageTitle && !pageTitle.includes('undefined')) && `${pageTitle} - Super Host`}/>

                <meta property="og:type" content={pageType}/>

                <meta property="og:image" content={pageImage}/>
                {pageType === 'article' && (
                    <meta name="author" content={pageAuthor}/>
                )}
            </Helmet>
        </>
    );
};

export default SeoHelmet;
