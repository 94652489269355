import DOMPurify from "dompurify";

// Configure DOMPurify to only allow specific tags and attributes used by React Quill
const config = {
  ALLOWED_TAGS: [
    "p",
    "h2",
    "strong",
    "em",
    "u",
    "s",
    "ol",
    "ul",
    "li",
    "a",
    "br",
  ],
  ALLOWED_ATTR: ["href", "target", "rel", "class", "style"],
  ALLOWED_STYLES: ["text-align"],
};

export const sanitizeHtml = (html) => {
  if (!html) return "";
  return DOMPurify.sanitize(html, config);
};
