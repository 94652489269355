// EventRender.js
import React from "react";
import noUser from "../../../assest/img/noUser.webp";

const EventRender = ({ event, setResDetailsId, setSelectedSlots }) => {
    return (
        <div
            onClick={() => {
                setResDetailsId(event);
                setSelectedSlots([]);
            }}
            className={`cursor-pointer px-2 flex text-white items-center justify-between py-1`}
        >
        <span className="flex items-center gap-3">
                {event.image === "cm" ? (
                    <div className="flex flex-shrink-0 w-10 h-10 rounded-full bg-black/50 justify-center items-center font-semibold">
                        {event.image.toUpperCase()}
                    </div>
                ) : (
                    <img
                        height={200}
                        width={200}
                        loading={"lazy"}
                        className="rounded-[50%] object-cover h-[1.8rem] lg:h-[2.5rem] w-[1.8rem] lg:w-[2.5rem]"
                        src={event.image ? event.image : noUser}
                        alt="Event"
                    />)}
        <strong>{event.title}</strong>
      </span>
            <div className="text-white flex text-xs lg:text-sm flex-col px-2">
                {event.id && <p>Total: {event?.Total?.toFixed(2)} TND</p>}
            </div>
        </div>
    );
};

export default EventRender;
