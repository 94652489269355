import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";

function UpdateReservationMethod({ ReservationMethod, setReservationMethod, Slog }) {
    const [Edit, setEdit] = useState(false);
    const [NewReservationMethod, setNewReservationMethod] = useState();
    const { t } = useTranslation();
    const { id } = useParams();

    useEffect(() => {
        setNewReservationMethod(ReservationMethod);
    }, [ReservationMethod, Edit]);

    async function SaveReservationMethod() {
        const UserId = localStorage.getItem("ID_Utulisateur");
        const userToken = Cookies.get("Token");
        
        try {
            const response = await axios.post(
                "https://visiontunisie.com/api/v1/GestionDesBiens/UpdateReservationMethod",
                {
                    Slog: id || Slog,
                    ID_Proprietaire: UserId,
                    ReservationMethod: NewReservationMethod,
                },
                {
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                    },
                }
            );
            
            setReservationMethod(NewReservationMethod);
            setEdit(false);
            toast.success(
                t("Msgs.Updatesuccessful", { text: t("Labels.ReservationMethod") })
            );
        } catch (err) {
            console.error(err);
            toast.error(t("Msgs.ErrorUpdating"));
        }
    }

    const items = [
        {
            key: "direct",
            label: t("Host.ReservationMethod.Direct"),
            desc: t("Host.ReservationMethod.DirectDescription"),
        },
        {
            key: "request",
            label: t("Host.ReservationMethod.ByRequest"),
            desc: t("Host.ReservationMethod.ByRequestDescription"),
        }
    ];

    return (
        <div className="flex flex-col gap-5 w-full lg:w-[50%]">
            <div className="flex flex-col gap-2 px-3">
                <div className="inputs flex flex-col w-full gap-3">
                    <h1 className="font-semibold text-lg">{t("Labels.ReservationMethod")}</h1>
                    {!Edit ? (
                        <div className="flex-col lg:flex-row gap-2 w-full flex items-start lg:items-center justify-between">
                            <h2 className="text-left">
                                {t(`Host.ReservationMethod.${ReservationMethod === "direct" ? "Direct" : "ByRequest"}`)}
                            </h2>
                            <button
                                className="font-semibold cursor-pointer text-sec"
                                onClick={() => setEdit(true)}
                            >
                                {t("Actions.Edit")}
                            </button>
                        </div>
                    ) : (
                        <div className="bg-white shadow-lg w-full border-[1px] rounded-md flex flex-col gap-3 p-3">
                            <div className="w-full items-center flex flex-col gap-5">
                                <div className="pt-2 flex w-full flex-col gap-5">
                                    {items.map((item) => (
                                        <div
                                            key={item.key}
                                            onClick={() => setNewReservationMethod(item.key)}
                                            className={`z-0 cursor-pointer px-5 border-solid border-[1px] py-2 rounded-md hover:z-30 ${
                                                NewReservationMethod === item.key ? "border-sec" : ""
                                            } relative w-full flex flex-col`}
                                        >
                                            <label
                                                htmlFor={item.key}
                                                className="cursor-pointer py-4 h-full z-20 flex font-semibold flex-row items-center justify-between w-full"
                                            >
                                                <h4 className="text-lg">{item.label}</h4>
                                                <input
                                                    className="accent-sec h-[1rem] w-[1rem]"
                                                    type="radio"
                                                    name="reservationOptions"
                                                    id={item.key}
                                                    checked={NewReservationMethod === item.key}
                                                    readOnly
                                                />
                                            </label>
                                            <div className="w-full text-gray-600 text-sm pt-0 pb-4 -mt-2">
                                                {item.desc}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>

                            <div className="border-t-[1px] pt-2 flex flex-row justify-between">
                                <button
                                    onClick={() => setEdit(false)}
                                    className="px-3 p-2 text-gray-400 border-[1px] rounded-md font-medium"
                                >
                                    {t("Actions.Cancel")}
                                </button>
                                <button
                                    onClick={SaveReservationMethod}
                                    className="px-3 p-2 bg-sec text-white rounded-md font-medium"
                                >
                                    {t("Actions.Save")}
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default UpdateReservationMethod;
