import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useTranslation } from "react-i18next";
import { sanitizeHtml } from "../../Utils/sanitizeHtml";

const modules = {
  toolbar: [
    [{ header: [2, false] }],
    ["bold", "italic", "underline", "strike"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link"],
    [{ align: [] }],
    ["clean"],
  ],
};

const PropertyDescriptionEditor = ({
  value,
  onChange,
  language,
  characterCount,
  minCharacters = 34,
}) => {
  const { t } = useTranslation();

  const handleChange = (html) => {
    const sanitizedHtml = sanitizeHtml(html);
    onChange(sanitizedHtml);
  };

  return (
    <div className="w-full flex flex-col gap-1 justify-end items-end">
      <ReactQuill
        value={value || ""}
        onChange={handleChange}
        className="w-full"
        placeholder={
          t("Host.Description&Title.PropertyDescription") + ` (${language})`
        }
        theme="snow"
        modules={modules}
      />
      <p
        className={`text-sm ${
          characterCount >= minCharacters ? "text-green-800" : "text-red-600"
        }`}
      >
        {t("Host.Description&Title.MinimumCharacters")} {characterCount}/
        {minCharacters}
      </p>
    </div>
  );
};

export default PropertyDescriptionEditor;
